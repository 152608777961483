import { useMemo } from 'react';

import { NUMBER_OF_SWATCHES_TO_SHOW } from 'commons/constants';
import useProjectSimonConfig from 'data-hooks/useProjectSimonConfig';
import getLowestTierVariantFirst from './helpers/getLowestTierVariantFirst';

// This hook generates the variantsToShow property for every product in the
// products array, and returns a copy of the products array that includes this
// additional property for each product. If specified, the colorFilters array
// is used to filter product variants to only include those that match the
// specified colors.

// The colorFilters array is of the format:  ['green', 'red']
const useProductVariantsToShow = ({
  colorFilters,
  products = [],
  upholsteryLabelFilters,
}) => {
  const { data: projectSimonConfig } = useProjectSimonConfig();
  const { isProjectSimonEnabled, projectSimonFabricSkus } = projectSimonConfig;

  const productsWithVariantsToShow = useMemo(
    () =>
      products.map((product, index) => {
        const { variants = [], variantsTotal = 0 } = product;

        // Only show variants that are valid for the Project Simon SKU filter.
        // If Project Simon is not enabled, hide any variants that includes the
        // specified Project Simon fabric SKUs.
        const matchingVariantsBySku =
          !isProjectSimonEnabled && projectSimonFabricSkus?.length
            ? variants.filter(
                ({ sku }) =>
                  !projectSimonFabricSkus.some(
                    skuToHide => skuToHide && sku?.includes(skuToHide)
                  )
              )
            : variants;

        // Only show variants that are valid for the selected color filters
        const matchingVariantsForColors = colorFilters?.length
          ? matchingVariantsBySku.filter(({ color: variantColor }) =>
              colorFilters.some(color => color.includes(variantColor))
            )
          : matchingVariantsBySku;

        // Only show variants that are valid for the selected upholstery labels
        const matchingVariants = upholsteryLabelFilters?.length
          ? matchingVariantsForColors.filter(({ label: variantLabel }) =>
              upholsteryLabelFilters.some(
                upholsteryLabel =>
                  !!upholsteryLabel && variantLabel.includes(upholsteryLabel)
              )
            )
          : matchingVariantsForColors;

        // If the first variant is not a tier 1 variant, move the first tier 1
        // variant found to the start of array. If no tier 1 variant is found,
        // then move the first tier 2 variant instead, and so on.
        const variantsWithLowestTierFirst = getLowestTierVariantFirst(
          matchingVariants,
          product.id
        );

        // Show maximum of 6 swatches
        const variantsToShow = variantsWithLowestTierFirst.slice(
          0,
          NUMBER_OF_SWATCHES_TO_SHOW
        );

        return {
          ...product,
          moreVariantsCount: variantsToShow.length
            ? variantsTotal - variantsToShow.length
            : 0,
          position: index + 1,
          variantsToShow,
        };
      }),
    [
      colorFilters,
      isProjectSimonEnabled,
      products,
      projectSimonFabricSkus,
      upholsteryLabelFilters,
    ]
  );

  return productsWithVariantsToShow;
};

export default useProductVariantsToShow;
